import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const ProductCuts = ({ productCutsdata, scrollToForm }: any) => {
  const [openIndexes, setOpenIndexes]: any = useState([]);

  const handleToggle = (index: number) => {
    setOpenIndexes((prevIndexes: number) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter(i => i !== index);
      }
      return [...prevIndexes, index];
    });
  };
  return (
    <div className="container">
      <div className="imagetags-wrapper">
        <h2 className="imagetags-heading text-green">
          {productCutsdata?.heading}
        </h2>
        <div className="imagetags-section">
          {productCutsdata?.productCutImage?.map((item: any, i: number) => {
            const { title = '', image, availablePackSize = '' } = item;
            if (!title) {
              return null;
            }
            const clickToScroll = title.toLowerCase().includes('enquire');
            return (
              <div className="imagetags-detail">
                <div className="imagetags-img">
                  {image?.localFile?.childImageSharp?.gatsbyImageData && (
                    <GatsbyImage
                      image={image?.localFile?.childImageSharp?.gatsbyImageData}
                      alt={title || ''}
                    />
                  )}
                </div>
                {clickToScroll ? (
                  <div
                    onClick={scrollToForm}
                    className="imagetags-tag_title"
                    role="presentation"
                    style={{ cursor: 'pointer' }}
                  >
                    {title}
                  </div>
                ) : (
                  <div className="imagetags-tag_title">{title}</div>
                )}
                {availablePackSize && (
                  <div className="imagetags-options">
                    {!openIndexes.includes(i) && (
                      <button
                        type="button"
                        className="imagetags-options-button close"
                        onClick={() => handleToggle(i)}
                      >
                        See available pack size <span>+</span>
                      </button>
                    )}

                    {openIndexes.includes(i) && (
                      <div className="imagetags-options-list-wrapper">
                        <button
                          type="button"
                          className="imagetags-options-button"
                          onClick={() => handleToggle(i)}
                        >
                          Available pack size
                          <span>-</span>
                        </button>
                        <ul className="imagetags-options-list">
                          {availablePackSize &&
                            availablePackSize?.length &&
                            availablePackSize.split(',').map((size: string) => {
                              return (
                                <li className="imagetags-options-list-item">
                                  {size}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export { ProductCuts };
